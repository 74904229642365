import React from "react";
import "./House.css";
import '../../../../App.css';

//Vertallen Prijssoort
const prijsSoort = (sale_condition) => {



  switch (sale_condition) {
    case 1: return "k.k"
    case 2: return "v.o.n"
    case 3: return "p/m"
    case 4: return "per kwartaal"
    case 5: return "per half jaar"
    case 6: return "per jaar"
    case 7: return "per contract"
    case 8: return "per m²"
    case 9: return "per m²"
    default: return null;
  }
}


const House = props => {
  const houseImg = props.houseInfo.main_img_url ? props.houseInfo.main_img_url.toString() : "";



  function addType(value1, value2) {
    if (value1 !== null && value2 !== null) {
      return value1 + " " + value2;
    }
  }

  const bottomData = {
    'Kamers': props.houseInfo.number_of_rooms,
    'Oppervlakte': addType(props.houseInfo.usable_area, "m²"),
    'Bouwjaar': props.houseInfo.year_built,
  }



  function getNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  function status() {
    //saleType is altijd 1 of 2 afhankelijk van of het iets met kopen of huren is
    //sale_status kan 1 - 6 zijn

    const translated = translateStatus(props.houseInfo.sale_type, props.houseInfo.sale_status);
    if (translated === false) {
      return;
    }
    return translated;

    function translateStatus(sale_type, sale_status) {
      switch (sale_type + "." + sale_status) {
      //[Status, backgroundColor, textColor] @#@LabelColor + text color
      case '1.1':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>New</div>

      case '1.2':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Te koop</div>

      case '2.2':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Te huur</div>

      case '1.3':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Verkocht o.v</div>

      case '1.4':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Verkocht</div>

      case '2.5':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Verhuurd</div>

      case '2.6':
        return <div className="status" style={{ backgroundColor: props.primary_color, color: '#fff' }}>Verhuurd o.v</div>

      default:
        return false;
      }
    }
  }

  

  /*
    props.houseInfo.label_new_color
    props.houseInfo.label_new_text_color
    props.houseInfo.label_forsale_color
    props.houseInfo.label_forsale_text_color
    props.houseInfo.label_sold_color
    props.houseInfo.label_sold_text_color
    props.houseInfo.label_sold_conditional_color
    props.houseInfo.label_sold_conditional_text_color
        <div className="house-img" Style={{backgroundImage: 'url('+props.houseInfo.media[0].filename+')'}} alt="House" /> 
  */

  return (
    <div id="house" className={props.viewState}>

      <div className="img-container font_semiBold" >
        {status(props.houseInfo)}


        <img className="house-img" key={houseImg} src={houseImg} alt="" />
      </div>

      <div className="text-container" >
        <div className="address fw">
          <span className="font_bold">{props.houseInfo.address_1}</span><span className="font_bold"> {props.houseInfo.house_number}</span>
          {props.houseInfo.house_number_addition && props.houseInfo_house_number_addition !== "0" ?
            <span className="font_bold"> {props.houseInfo.house_number_addition}</span>
            : null}
          <div className="address-city"> {props.houseInfo.city}</div>
        </div>

        {props.houseInfo.price ?
          <div className="price font_italic" style={{ color: props.secondary_color }}>
            <span>&euro; {getNumberWithCommas(props.houseInfo.price)},-</span> &nbsp;  {prijsSoort(props.houseInfo.sale_condition)}
          </div> : <div className="price font_italic" style={{ height: '23px' }}></div>}

        <div className="table">
          {
            Object.keys(bottomData).map((content, index) => ( //dit is per 'blok/tote;'          
              Object.values(bottomData)[index] && Object.values(bottomData)[index] !== "0" ?
                <div key={index}>
                  <div className={props.viewState === "Display1" ? "key font_semiBold" : 'key'}>{Object.keys(bottomData)[index]} </div>&nbsp;
                <div >{Object.values(bottomData)[index]}</div>
                  &nbsp;{Object.values(bottomData)[index + 1] && props.viewState === "Display2" ? "-" : ""}&nbsp;
              </div>
                : null
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default House;

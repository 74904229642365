
export default function (state = [], action) {
    switch (action.type) {
        case "ORGANISATION_SELECTED":
            return {
                ...state,
                name: action.payload
            }
        case "ORGANISATION_UPDATED":
            return action.payload;        //dit update als het goed is alles in de organisatie

        default:
            return state;
    }
}


import React from "react";
import "./HeaderSlide.css";
import ViewSwitch from "./ViewSwitch";
import Drawer from "../TemporaryDrawer/PersistentDrawer";
import { connect } from "react-redux";
import SearchBar from "../contentSlide/houseList/searchBar/SearchBar";


function HeaderSlide(props) {
  return (

    //HouseList               
    <div className="header-slide" style={{ backgroundColor: props.organisation.primary_color }} >
      <div className="icon-container">
{/* @#@ header_logo */}
        <img src={props.organisation.logo} alt="" className="logo" />
        {props.status === "HouseList" ?
          <>

            <Drawer ></Drawer>
            <SearchBar></SearchBar>
            <ViewSwitch secondary_color={props.organisation.secondary_color} ></ViewSwitch>
          </>
          :
          <><div className="arrow-return" style={{ 'WebkitMask': "url(" + require("../../assets/pictures/chevron-left.svg") + ") no-repeat center" }}></div><span className="backTextHeader ">Terug</span> </>
        }

      </div>
    </div>


  );
}

// <img src={props.organisation.logo} alt="" className="cornerLogo icon" />

function mapStateToProps(state) {
  return { organisation: state.organisation }
}

export default connect(mapStateToProps)(HeaderSlide)

import React, { useState } from "react";
import "./radio.css";


export default function RadioQuestion(props) {
    const [collapse, setCollapse] = useState(false);
    const [collapseText, setCollapseText] = useState(false);


    if(props.currentAnswer && !collapse){

        setCollapse(true)
        setCollapseText(props.currentAnswer)
    }
    if(!props.currentAnswer && collapse)
    {
        setCollapse(false)
    }
    
    function change(answer, location) {
        props.onChildClick(answer, location)
    }

    const cross_url = "url(" + require("../../../../assets/pictures/times.svg") + ") no-repeat center"
    return (
        collapse ?

            <div className="deCollapseContent" onClick={() => change(null, props.item.location)} style={{ border: '3px dashed ' + props.secondary_color }}>
                <div className="deCollapseCross" style={{ 'WebkitMask': cross_url }} alt=""></div>
                <div className="deCollapseText">{collapseText}+ m<sup>2</sup></div>
            </div>

            :

            props.item.answers.map((answer, index2) => (
                <div className="list__item" key={"answer_" + props.index + "." + index2}>
                    <label className="label--radio" onClick={() => change(answer, props.item.location)}>
                        <input type="radio" className="radio" style={{ backgroundColor: props.secondary_color }} name="foo" />
                        <div className="text">
                            {answer}+ m<sup>2</sup>
                        </div>
                    </label>
                </div>
            ))
    )
}
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

//import { updateRequirements } from '../../../../store/actions';
import { updateOrganisation } from "../../store/actions";
import { updateRequirements } from "../../store/actions";
//import { filterSelector } from '/../../../store/reducers/reducer-displayed-houses';
import { bindActionCreators } from "redux";
//import { doesAPIexist } from '../store/globalAPIFunctions'
//import { getAPIdata } from '../store/globalAPIFunctions'
//import ContentSlide from "../components/contentSlide/ContentSlide.js";
//import HeaderSlide from "../components/headerSlide/HeaderSlide.js";
import "./loginPage.css";

function LoginPage(props) {
    let history = useHistory();
    var logourl =
        "https://viewiemedia.nl/wp-content/themes/ysd-basic-child/assets/images/logo.svg";
    const [writtenToken, setWrittenToken] = useState("");
    const [agencyFound, setAgencyFound] = useState(false);

    async function handleChange(event) {
        setWrittenToken(event.target.value);

        doesAPIexist(event.target.value);
    }




    async function doesAPIexist(id) {
        //JsonP is a bit slow, cant look at the head. so this is a cheat for now
        if(id > 0){
            return setAgencyFound(true);
        }
        else return setAgencyFound(false);
    }


    async function handleClick() {
        const localWT = writtenToken;
        if (agencyFound) {
            props.updateOrganisation(localWT);
            history.push("/" + localWT);
            return;
        }
    }

    return (
        <div id="LoginPage">
            <div className="fullPageContainer">
                <div className="text-center loginContainer">
                    <div htmlFor="exampleInputPassword1" className="mb-3">
                        Voer hier de token/ID in van de betreffende organisatie
                    </div>
                    <input
                        type="number"
                        autoComplete="off"
                        min="0"
                        style={{ boxSizing: "border-box" }}
                        className="form-control"
                        id="exampleFormControlFile1"
                        value={writtenToken}
                        onChange={handleChange}
                        placeholder="Token"
                    />
                    <button
                        type="button"
                        disabled={!agencyFound}
                        id="button"
                        className={"btn btn-success mt-3"}
                        onClick={() => handleClick()}
                    >
                        {agencyFound ? (
                            logourl ? (
                                <img
                                    src={logourl}
                                    alt=""
                                    style={{ height: "25px", minWidth: "100px" }}
                                />
                            ) : (
                                "Gevonden, klik hier"
                            )
                        ) : (
                            "Geen makelaar gevonden"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateRequirements: updateRequirements,
            updateOrganisation: updateOrganisation
        },
        dispatch
    );
}

export default connect(null, matchDispatchToProps)(LoginPage);

import React, { useState } from "react";
import CheckBox from "./checkBox/CheckBox";
import { connect } from "react-redux";
import { updateRequirements } from "../../../store/actions";

import { updateDisplayedHouseList } from "../../../store/actions";
import { filterSelector } from "../../../store/reducers/reducer-displayed-houses";
import { bindActionCreators } from "redux";
import RadioQuestion from "./radio/RadioQuestion";
import "./FilterForm.css";
import { updateSearchBar } from "../../../store/actions";
import Select from "./select/Select.js";

const resetRequirements = {
    saleType: 1, //Kopen
    minPrice: null,
    maxPrice: null,
    type: [],
    min_number_of_rooms: null,
    max_number_of_rooms: null,
    usable_area: null, //radio
    plot_area: null
};

function FilterForm(props) {

    const [localRequirements, setLocalRequirements] = useState(props.requirements);
    var minPriceAnswers;
    var maxPriceAnswers;
    const MIN_NUMBER_OF_ROOMS = ["1", 2, 3, 4, 5];
    const MAX_NUMBER_OF_ROOMS = [3, 5, 7, 9, "Geen max"];

    const filteredHouses = filterSelector(props.AllHouses, localRequirements);

    function itemClicked(answer, location) {
        // props.updateRequirements(answer, "R_" + location + "_UPDATED");

        let newObj = JSON.parse(JSON.stringify(localRequirements))

        switch (location) {

            case "SALE_TYPE":
                newObj.saleType = answer;

                newObj.minPrice = null;
                newObj.maxPrice = null;
                break;
            case "MINPRICE":
                newObj.minPrice = answer;
                break;
            case "MAXPRICE":
                newObj.maxPrice = answer;
                break;
            case "TYPE":
                if (!isValueInArray(newObj.type)) {
                    newObj.type.push(answer)
                    //      newObj.type = answer;
                }
                else {
                    newObj.type = newObj.type.filter(e => e !== answer);
                }
                break;
            case "MIN_NUMBER_OF_ROOMS":
                newObj.min_number_of_rooms = answer;
                break;
            case "MAX_NUMBER_OF_ROOMS":
                newObj.max_number_of_rooms = answer;
                break;
            case "USABLE_AREA":
                newObj.usable_area = answer;
                break;
            case "PLOT_AREA":
                newObj.plot_area = answer;
                break;
            default:

        }

        // setLocalRequirements(...localRequirements, maxPrice:'99999'  )

        function isValueInArray(location) {
            return location.indexOf(answer) > -1; //Is NOT in array
        }

        setLocalRequirements(newObj);
    }

    function resetAll() {
        setLocalRequirements(resetRequirements);
        // props.updateRequirements(resetRequirements, "ALL_REQUIREMENTS_UPDATED");
    }

    if (localRequirements.saleType === 1) {
        //sale
        minPriceAnswers = [0, 150000, 300000, 500000, 700000, 1000000];
        maxPriceAnswers = [400000, 600000, 1000000, 1500000, "Geen max"];
    } else {
        //rent
        minPriceAnswers = [0, 500, 1000, 1500, 2000, 5000, 4000];
        maxPriceAnswers = [2000, 3000, 5000, 7000, "Geen max"];
    }

    function filterList(list, maxOrMin, otherListItem) {
        if (maxOrMin === "min") {
            for (let i = 0; i < list.length; i++) {
                if (list[i] > otherListItem && otherListItem !== null) {
                    list.splice(i, 1);
                    i--;
                }
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                if (list[i] < otherListItem && otherListItem !== null) {
                    list.splice(i, 1);
                    i--;
                }
            }
        }
        return list;
    }



    const formList = [
        {
            type: "switch",
            location: "SALE_TYPE",
            answers: [
                [1, "Koop"],
                [2, "Huur"]
            ]
        },
        {
            title: "Prijs",
            innerText: "van",
            type: "select",
            context_before: "€",
            location: "MINPRICE",
            actualValue: localRequirements.minPrice,
            answers: filterList(minPriceAnswers, "min", localRequirements.maxPrice)
        },
        {
            innerText: "tot",
            type: "select",
            context_before: "€",
            location: "MAXPRICE",
            actualValue: localRequirements.maxPrice,
            answers: filterList(maxPriceAnswers, "max", localRequirements.minPrice)
        },
        {
            title: "Soort aanbod",
            type: "checkBox",
            location: "TYPE",
            answers: ["Woning", "Appartement"],
            selectedValues: localRequirements.type,
        },
        {
            title: "Aantal kamers",
            innerText: "van",
            type: "select",
            location: "MIN_NUMBER_OF_ROOMS",
            actualValue: localRequirements.min_number_of_rooms,
            answers: filterList(
                MIN_NUMBER_OF_ROOMS,
                "min",
                localRequirements.max_number_of_rooms
            )
        },
        {
            innerText: "tot",
            type: "select",
            location: "MAX_NUMBER_OF_ROOMS",
            actualValue: localRequirements.max_number_of_rooms,
            answers: filterList(
                MAX_NUMBER_OF_ROOMS,
                "max",
                localRequirements.min_number_of_rooms
            )
        },
        {
            title: "Woonoppervlakte",
            type: "radio",
            location: "USABLE_AREA",
            currentAnswer: localRequirements.usable_area,
            answers: ['0', 50, 75, 100, 150]
        },
        {
            title: "Perceeloppervlakte",
            type: "radio",
            location: "PLOT_AREA",
            currentAnswer: localRequirements.plot_area,
            answers: ['0', 250, 500, 1000, 2500]
        }
    ];



    function answers(index, item) {
        switch (item.type) {
            case "switch":
                return (
                    <div key={index} className="switch">
                        {item.answers.map((answer, index) =>
                            localRequirements.saleType === answer[0] ? ( //Check if answer is same as redux
                                <span
                                    key={index}
                                    style={{ backgroundColor: props.secondary_color }}
                                    className="switchpart selected"
                                >
                                    {answer[1]}
                                </span> //not clickable
                            ) : (
                                <span
                                    key={index}
                                    className="switchpart not_selected"
                                    onClick={() => itemClicked(answer[0], item.location)}
                                >
                                    {answer[1]}
                                </span>
                            )
                        )}
                    </div>
                );

            case "checkBox":
                return item.answers.map((answer, index2 = { index }) => (
                    <CheckBox
                        secondary_color={props.secondary_color}
                        onChildClick={answer => itemClicked(answer, item.location)}
                        num={`${index}.${index2}`} // `${index}.${index2}` //`${var}.${var}` == hear that this syntax is better
                        key={`${index}.${index2}`}
                        text={answer}
                        selectedValues={item.selectedValues}
                    ></CheckBox>
                ));
            case "select":
                return (
                    <Select
                        secondary_color={props.secondary_color}
                        actualValue={item.actualValue}
                        item={item}
                        onChildClick={answer => itemClicked(answer, item.location)}
                    ></Select>
                );

            case "radio":
                return (
                    <RadioQuestion
                        secondary_color={props.secondary_color}
                        index={index}
                        onChildClick={answer => itemClicked(answer, item.location)}
                        key={"Question_" + index}
                        currentAnswer={item.currentAnswer}
                        item={item}
                    ></RadioQuestion>
                );

            default:
                console.error("Geen geldig type vraag(radio/checkbox/select");
                return;
        }
    }

    const QA_list = formList.map((item, index) => {
        return (
            <span key={"Question_" + index} className={"question Question_" + index}>
                {item.title ? (
                    <h5 className="font_semiBold" key={index}>
                        {item.title}
                    </h5>
                ) : null}
                {answers(index, item)}
            </span>
        );
    });

    return (
        <div id="FilterForm">
            <div style={{ color: props.primary_color }} className="resetButton" onClick={() => resetAll()}>Reset filters</div>
            <img
                onClick={() => props.onChildClick()}
                style={{
                    WebkitMask:
                        "url(" +
                        require("../../../assets/pictures/cross-square.svg") +
                        ") no-repeat center"
                }}
                alt=""
                className="rot90 exitform svg-icon"
            />
            {QA_list}
            <div className="fixed-button font_semiBold">
                {filteredHouses.length ? (
                    <div className="confirmButton" onClick={() => confirm()}>
                        Toon {filteredHouses.length} huizen
                    </div>
                ) : (
                    <div
                        className="confirmButton"
                        style={{ opacity: 0.4, cursor: "auto" }}
                    >
                        Geen huizen gevonden
                    </div>
                )}
            </div>
        </div>
    );

    function confirm() {
        props.updateRequirements(localRequirements);
        props.updateDisplayedHouseList(filteredHouses);
        props.updateSearchBar('');
        props.onChildClick(); //Close drawer
    }
}

function mapStateToProps(state) {
    return {
        requirements: state.requirements,
        secondary_color: state.organisation.secondary_color,
        primary_color: state.organisation.primary_color,
        AllHouses: state.AllHouses,
        // filteredHouses: filterSelector(state.AllHouses, state.requirements),

    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateSearchBar: updateSearchBar,
            updateRequirements: updateRequirements,
            updateDisplayedHouseList: updateDisplayedHouseList
        },
        dispatch
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(FilterForm);
import React from "react";
import "./GolfPage.css";
import GolfTeeComponent from "../../components/golfTee/GolfTeeComponent";
import {useLocation} from "react-router-dom";

const GolfPage = ({ version }) => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    window.onload = function () {
        var timeout_ms = query.get("timeout");
        try {
            if (this.viewiemedia_clientCall && timeout_ms > 0) {
                console.log('clientcall = true', timeout_ms)

                if (parseInt(timeout_ms)) {
                    inactivityTime(timeout_ms);
                }
            }
            else console.log('clientcall met timeout = false')
        } catch (e) { }
    };

    var inactivityTime = function (timeout_ms) {
        console.log('inactivityTime')
        var time;
        // document.onload = resetTimer;
        resetTimer();

        // document.onmousemove = resetTimer;
        // document.onmousedown = resetTimer;
        // document.ontouchstart = resetTimer;
        document.onclick = resetTimer;
        // document.onkeypress = resetTimer;
        document.addEventListener("scroll", resetTimer, true);

        function exitt() {
            try {
                console.log('exitt touch aangeroepen')
                this.viewiemedia_clientCall.exitTouchMode();
            } catch (e) {
                console.log(e);
            }

            //      alert(timeout_ms);

            clearTimeout(time);
        }

        function resetTimer() {
            console.log('reset timer')
            clearTimeout(time);
            time = setTimeout(exitt, timeout_ms);
        }
    };

    return (
        <div className="screen">
            <GolfTeeComponent version={version}/>
        </div>
    );
};

export default GolfPage;

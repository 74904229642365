export default function (state = [], action) {
    switch (action.type) {
        case "ALL_REQUIREMENTS_UPDATED":

            return action.payload;

     /*   case "R_SALE_TYPE_UPDATED":
            return {
                ...state,
                saleType: action.payload,         //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
                minPrice: null,
                maxPrice: null,        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }


        case 'R_MAXPRICE_UPDATED':
            return {
                ...state,
                maxPrice: action.payload         //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }

        case 'R_MINPRICE_UPDATED':
            return {
                ...state,
                minPrice: action.payload        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }

        case 'R_TYPE_UPDATED':
            if (!isValueInArray(state.type)) {
                return {
                    ...state,
                    type: [...state.type, action.payload]
                }
            }
            return {
                ...state,
                type: state.type.filter(item => item !== action.payload)
            }

        case 'R_USABLE_AREA_UPDATED':
            return {
                ...state,
                usable_area: action.payload        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }

        case 'R_PLOT_AREA_UPDATED':
            return {
                ...state,
                plot_area: action.payload        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }


        case 'R_MIN_NUMBER_OF_ROOMS_UPDATED':
            return {
                ...state,
                min_number_of_rooms: action.payload        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }

        case 'R_MAX_NUMBER_OF_ROOMS_UPDATED':
            return {
                ...state,
                max_number_of_rooms: action.payload        //Hoe zorg je dat maxprice.../locatie vananaf filterform kan worden aangeroepen
            }


*/

        default:
            return state;
    }
}




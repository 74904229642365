import React, { useState } from "react";
import { connect } from "react-redux";
import { filterSelector } from "../../../../store/reducers/reducer-displayed-houses";
import "./SearchBar.css";
import { updateDisplayedHouseList } from "../../../../store/actions";
import { updateSearchBar } from "../../../../store/actions";
import { bindActionCreators } from "redux";
import { updateRequirements } from "../../../../store/actions";



function SearchBar(props) {
  

  //##normale variable kan length van aantal letters in word opslaan zodat je kan zien of het anatal omhoog/omlaag gaat om te bepalen of de lijst moet veranderne
  // var previousValueWord =

  //gets displayedHouseList
  //scrambles it
  //passes it back

  //hoe zie je wanneer het herladen word
  var requirementHousesList;
  var allHousesList;
  const [isSearchBarOpened, setIsSearchBarOpened] = useState(false);

  const [usedHousesList, setUsedHousesList] = useState(props.displayedHouses);
  // console.log("searchbar opnieuw geladen "+usedHousesList.length );
  // if (usedHousesList !== props.displayedHouses && usedHousesList) {
  //   console.log("updateDisplayedHouselistt");
  //   try {
  //     props.updateDisplayedHouseList(usedHousesList);
  //   } catch {
  //     console.log("error");
  //   }
  // }

  //var displayedHouses = props.displayedHouses; //kan worden genegeerd bij overwrite

  //function sortOnPrice(a, b) {
  //  //sort(sortOnPrice)             //gebeurd al standaart in totale lijst
  //  if (a.price < b.price) {
  //    return -1;
  //  }
  //  if (a.price > b.price) {
  //    return 1;
  //  }
  //  return 0;
  //}

  function order(word) {
    //input = WordValue,,,, output = huidig zichtbare huizenarray MET VOLGORDE
    // console.log("Order functie");
    var matchArray = [];
    var noMatchArray = [];
    // console.log(usedHousesList)
   // if (word.length < props.searchValue.length) {
   //   //displayedHouses = usedHousesList.sort(sortOnPrice);
   // }

    for (let i = 0; i < usedHousesList.length; i++) {
      if (
        (usedHousesList[i].description && usedHousesList[i].description.toLowerCase().includes(word.toLowerCase())) ||
        (usedHousesList[i].city && usedHousesList[i].city.toLowerCase().includes(word.toLowerCase())) ||
        (usedHousesList[i].address_1 && usedHousesList[i].address_1.toLowerCase().includes(word.toLowerCase())) || 
        (usedHousesList[i].zipcode && usedHousesList[i].zipcode.toLowerCase().includes(word.toLowerCase()))
      ) {
        matchArray.push(usedHousesList[i]);
      } else {
        noMatchArray.push(usedHousesList[i]);
      }
    }
    return matchArray;
    //return matchArray.concat(noMatchArray);
  }

  function handleChange(event) {
    // console.log("handlechange");

    if (isSearchBarOpened) {


     // console.log(usedHousesList)
     // console.log(  filterSelector(props.AllHouses, null))

      if (
        event.target.value.length === 1 
       &&  usedHousesList.join(",") !== filterSelector(props.AllHouses, null).join(',') //probleem is idd dat usedHouseList niet sort
      ) {
  
        allHousesList = filterSelector(props.AllHouses, null);
        props.updateDisplayedHouseList(allHousesList); //only needed if searchbar overwrites filterform, otherwise it can be props.DisplayedHouses
        setUsedHousesList(allHousesList);
        


      } else {
        // console.log("GEen backtohouses")
        // props.updateDisplayedHouseList(order(event.target.value));
        //    setUsedHousesList(order(event.target.value));
        // setUsedHousesList(order(event.target.value));
        props.updateDisplayedHouseList(order(event.target.value));
      }
      props.updateSearchBar(event.target.value);


  
    }
    //update
  }

  function blurFunction() {

  //  resetAll()
  //  function resetAll() {
  //    const initialRequirement = {
  //      saleType: 1, //Kopen
  //      minPrice: null,
  //      maxPrice: null,
  //      type: [],
  //      min_number_of_rooms: null,
  //      max_number_of_rooms: null,
  //      usable_area: null, //radio
  //      plot_area: null
  //    };
  //    props.updateRequirements(initialRequirement, "ALL_REQUIREMENTS_UPDATED");
  //  }


    setIsSearchBarOpened(false);

    if (
      props.searchValue.length === 0 &&
      usedHousesList !== props.displayedHouses
    ) {
      requirementHousesList = filterSelector(
        props.AllHouses,
        props.requirements
      );


      

      setUsedHousesList(requirementHousesList);

      props.updateDisplayedHouseList(requirementHousesList);
    }
  }

  return (
    <div
      className="searchContainer"
      style={{ backgroundColor: props.primary_color }}
    >
      {isSearchBarOpened ? (
        <input
          autoFocus
          maxLength="25"
          className="searchInput"
          type="text"
          onBlur={() => blurFunction()}
          value={props.searchValue}
          onChange={handleChange}
        />
      ) : props.searchValue.length > 0 ? ( //is er wel text maar gesloten
        <input
          maxLength="25"
          className="searchInput semiClosed"
          onMouseDown={() => setIsSearchBarOpened(true)}
          type="text"
          value={props.searchValue}
          onChange={handleChange}
        />
      ) : null}

      {!isSearchBarOpened ? (
        <div className="searchIconContainer">
          <div
            className="searchIcon"
            onClick={() => setIsSearchBarOpened(true)}
            style={{

              WebkitMask:
                "url(" +
                require("../../../../assets/pictures/search.svg") +
                ") no-repeat center"
            }}
          ></div>
        </div>
      ) : (
        <div
          className="searchIcon background"
          style={{
            WebkitMask:
              "url(" +
              require("../../../../assets/pictures/search.svg") +
              ") no-repeat center"
          }}
        ></div>
      )}
    </div>
  );
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //What do we write to the redux store
      //<>does that even happend from this file? maybe it does
      updateSearchBar: updateSearchBar,
      updateRequirements: updateRequirements,
      updateDisplayedHouseList: updateDisplayedHouseList
    },
    dispatch
  );
}
function mapStateToProps(state) {
  return {
    AllHouses: state.AllHouses,
    // filteredHouses: filterSelector(state.AllHouses, state.requirements),
    //nog opgeslagen filter
    // unFilteredHouses:  filterSelector(state.AllHouses, null),     //only if searchbar overwrites filterform
    primary_color: state.organisation.primary_color,
    displayedHouses: state.displayedHouses,
    searchValue: state.searchbar,
    requirements: state.requirements
  };
}
export default connect(mapStateToProps, matchDispatchToProps)(SearchBar);

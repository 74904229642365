import { combineReducers } from "redux";
import ActiveViewStateReducer from "./reducer-active-viewstate";
import RequirementsReducer from "./reducer-requirements";
import OrganisationReducer from "./reducer-organisation";
import AllHousesReducer from "./reducer-all-houses";
import displayedHousesReducer from "./reducer-displayed-houses";
import appStatusReducer from "./reducer-appstatus";
import searchbarReducer from "./reducer-searchbar";

const allReducers = combineReducers({
  //ROEPT ALLES AAN
  // viewStates: ViewStateReducer,
  searchbar: searchbarReducer,
  activeViewState: ActiveViewStateReducer,
  requirements: RequirementsReducer, //{}
  organisation: OrganisationReducer,
  //Of houses of data kan weg toch?
  AllHouses: AllHousesReducer,
  appStatus: appStatusReducer,
  displayedHouses: displayedHousesReducer
  //r_maxPrice: maxPriceReducer,  //test
});

export default allReducers;

import React from "react";
import ContentSlide from "../components/contentSlide/ContentSlide.js";
import HeaderSlide from "../components/headerSlide/HeaderSlide.js";

function MainPage() {


    //,height: '100vh', overflowY: 'hidden'
    return (
        <div id="mainPage" >
            <HeaderSlide status="HouseList"></HeaderSlide>
            <ContentSlide status="HouseList"></ContentSlide>
        </div>
    );
}




export default MainPage;
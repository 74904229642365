import React from 'react';
import './CheckBox.css';

const CheckBox = (props) => {
   
    //defaultChecked
    var isChecked = false;
    function check(c = false) {
        if (c === true) {

            if (document.getElementById(props.num).checked) {
                document.getElementById(props.num).checked = false;
            }
            else {
                document.getElementById(props.num).checked = true;
            }

        }
        props.onChildClick(props.text)
    }

    if(props.selectedValues.includes(props.text)){
        isChecked = true;
    }

    return (
        <div id="CheckBox">
            <div className="styled-input-single" onClick={() => check(true)} type="checkbox">
                <input type="checkbox" name="fieldset-6" id={props.num} onChange={()=>''} checked ={isChecked} />
                <label style={{ backgroundColor: props.secondary_color }} htmlFor={props.num}></label>{props.text}
            </div>
        </div>
    );
}

export default CheckBox;

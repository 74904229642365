import React from "react";
import './GolfPopup.css';

const Popup = ({ message, onClose}) => {
    return (
        <div className="popup">
            <div className="popup-inner">
                <p>{message}</p>
                <button className="button button--close" onClick={onClose}>OK</button>
            </div>
        </div>
    )
}

export default Popup;

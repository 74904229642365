export default function(state = [], action) {
  switch (action.type) {
    case "DISPLAYED_HOUSELIST_UPDATED":
      return action.payload;
    default:
      return state;
  }
}

function keepNeededElements(house) {
  var main_img_url = "";
  // console.log(house)
  if (house.media[0] != null) {
    main_img_url = house.media[0].url; //house.media[0].url; makes everything crash if doesnt exist
  }
  return {
    id: house.id,
    main_img_url: main_img_url,
    address_1: house.address_1,
    description: house.description,
    house_number: house.house_number,
    house_number_addition: house.house_number_addition,
    address_2: house.address_2,
    zipcode: house.zipcode,
    city: house.city,
    price: house.price,
    number_of_rooms: house.number_of_rooms,
    usable_area: house.usable_area,
    year_built: house.year_built,
    sale_status: house.sale_status,
    sale_type: house.sale_type,
    sale_condition: house.sale_condition
    //some of these values can be removed if SearchBar overwrites Filterform
  };
}

export function filterSelector(houses, requirements) {

  const filteredHouses = [];
  if (houses) {
    if (!requirements) {
      for (let i = 0; i < houses.length; i++) {
        var houseOnlyNeeded = keepNeededElements(houses[i]);
        if (houseOnlyNeeded) {
          filteredHouses.push(houseOnlyNeeded);
        }
      }
    } else if (requirements) {
      // console.error('keep elements')
      for (let i = 0; i < houses.length; i++) {
        //Filtert wanneer viewstate verandert
        //Plaats gene met moeilijkere functies onderaan/dan hoeven die niet voor elke worden uitgevoerd
        if (
          requirements.minPrice <= houses[i].price && (houses[i].price <= requirements.maxPrice ||
            typeof requirements.maxPrice !== "number") && (requirements.usable_area <= houses[i].usable_area ||
            requirements.usable_area === null) && (requirements.plot_area <= houses[i].plot_area ||
            requirements.plot_area === null) && (requirements.min_number_of_rooms <= houses[i].number_of_rooms ||
            typeof requirements.min_number_of_rooms !== "number") && (houses[i].number_of_rooms <= requirements.max_number_of_rooms ||
            typeof requirements.max_number_of_rooms !== "number") && //       && (requirements.number_of_rooms.includes(houses[i].number_of_rooms) || requirements.number_of_rooms.length === 0)
            (requirements.type.includes(translateHouseType(houses[i].type)) ||
            requirements.type.length === 0) && requirements.saleType === houses[i].sale_type
        ) {
          filteredHouses.push(keepNeededElements(houses[i]));
        }
      }
    } else {
      console.log("geen houses gevonden in filter");
    }
  }

  return filteredHouses;
}

//type(housetype) is written in the DB as a integer, this translates it to a string
function translateHouseType(i) {
  return ["Woning", "Appartement"][i - 1]; //-1 Because start at 0
}

export default function (state = [], action) {

  switch (action.type) {
    case "APPSTATUS_UPDATED":

      return action.payload;
    default:
      return state;
  }
}

import React, { useState } from "react";
import { connect } from "react-redux";
import "./HouseList.css";
import House from "./house/House.js";
import { Link } from "react-router-dom";
import '../../../App.css';


function HouseList(props) {





  window.scrollTo(0, 0)
  //houselist komt binnen
  const [housesList, setHousesList] = useState(props.houses)
  const [displayPage, setDisplayPage] = useState(0);

  if (props.houses !== housesList) {      //If a different list comes in you must go to page 1 ==Whole reason housesList is a state
    setHousesList(props.houses);
    setDisplayPage(0)
  }

  const numberOfHousesPerPage = 20;
  const numberDisplayedHouses = [displayPage * numberOfHousesPerPage, (displayPage * numberOfHousesPerPage) + numberOfHousesPerPage]   //10 results per page
  const numberOfPages = Math.ceil(props.houses.length / numberOfHousesPerPage); //34 / 10 == 3.4 ==3


    return (

      <div className="houseListContainer">

        {
        Array.isArray(housesList) && housesList.length > 0 ?
        <div className={"houseList list-" + props.viewState} >   
          {
            housesList.slice(numberDisplayedHouses[0], numberDisplayedHouses[1]).map((house, index) =>
              <Link to={
                window.location.pathname + "/" + house.id
              } key={index}>
                <House className={"house-" + props.viewState} secondary_color={props.organisation.secondary_color} primary_color={props.organisation.primary_color} houseInfo={house} viewState={props.viewState} />
              </Link>
            )
          }
        </div>
         :   
         <div className="noHousesMessage text-center mt-5">Geen huizen gevonden...</div>
        }

        {numberOfPages > 1 ?
          <div className="pageNumberContainer">
            {
              [...Array(numberOfPages)].map((e, i) => <div style={{ backgroundColor: displayPage === i ? '#f2f2f2' : null }} className="pageNumber" onClick={() => setDisplayPage(i)} key={i + 1}>{i + 1}</div>)
            }
          </div>
          : null

        }

      </div>

    );
  };

  //Line +-19 house.data[0] has to be replaced because this path is according to the



function mapStateToProps(state) {
  return {
    viewState: state.activeViewState,
    houses: state.displayedHouses,
    organisation: state.organisation,
  }
}

export default connect(mapStateToProps, "")(HouseList);
import React from "react";
import HouseList from "./houseList/HouseList.js";
import "./ContentSlide.css";
import SingleHouse from "./singleHouse/SingleHouse.js";
import { connect } from "react-redux";

function ContentSlide(props) {
  function exitt() {
    try {
      props.appStatus.exitTouchMode();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div className="content-slide">
        {props.status === "HouseList" ? (
          <HouseList></HouseList>
        ) : (
            <SingleHouse></SingleHouse>
          )}
      </div>

      <div className={props.appStatus ? "footer h150" : "footer"}>
        {props.appStatus ? (
          <div className="buttonBackToSlideshow" onClick={() => exitt()}>
            Terug naar de slideshow
          </div>
        ) : (
            <></>
          )}
        <div className="footer-container">
          {/* @#@ logo bototm */}
          <img src={props.logo} alt="" className="logoBottom left" />
          <div className="logoBottom right">
            Een product van <b>Viewie Media</b>
          </div>
        </div>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return {
    logo: state.organisation.logo,
    appStatus: state.appStatus
  };
}
export default connect(mapStateToProps)(ContentSlide);

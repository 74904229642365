import React, { useState } from "react";
import './Select.css';

function Select(props) {
    const [isOpened, setIsOpened] = useState(false)



    function setNewValue(newValue) {
        setIsOpened(!isOpened)
        if (newValue !== props.item.actualValue) {     
            props.onChildClick(newValue)
        }
    }

    function f_context_before() {
        if (props.item.context_before) {
            return props.item.context_before;
        }
        return '';
    }

    function getNumberWithCommas(number) {
        if (typeof number === 'number' &&number > 999) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
  
        return number;
    }

    return (
        <div className="SelectContainer">
            <div className="Select" onClick={() => setIsOpened(!isOpened)}> 

                <img className={isOpened ? "svg-icon dropdownLogo rot180" : "svg-icon dropdownLogo rot0"} style={{ backgroundColor: props.secondary_color, 'WebkitMask': "url("+require("../../../../assets/pictures/angle-down.svg")+") no-repeat" }} alt="" />
                <span className="textInSelect">{props.item.innerText}</span>{typeof props.item.actualValue === 'number' ? f_context_before() + getNumberWithCommas(props.item.actualValue) 
                    : getNumberWithCommas(props.item.actualValue)  }

            </div>
            {isOpened ?
                <>
                    <div className="invisible_block" onClick={() => setIsOpened(false)}></div>

                    <div className="Options">
                        {props.item.answers.map((answer, index) => (
                            <div className="option" onClick={() => setNewValue(answer)}
                                key={index + "." + index}>
                                {typeof answer === 'number' ? f_context_before() + getNumberWithCommas(answer) : answer}
                            </div>
                        ))}
                    </div>
                </> : null
            }
        </div>
    )
}
export default Select;
import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import allReducers from "./reducers";

const initialState = {
  appStatus: null, //to backbutton
  activeViewState: "Display1",
  searchbar: '',
  AllHouses: null, //allData.properties.sort(sortOnPrice),
  displayedHouses: null,

  requirements: {
    saleType: 1,
    minPrice: null,
    maxPrice: null,
    type: [],
    min_number_of_rooms: null,
    max_number_of_rooms: null,
    usable_area: null, //radio
    plot_area: null //REAL INITIALVALUES GET CALLED IN MAINPAGE.js
  },

  organisation: {
    logo: null,

    primary_color: null,
    secondary_color: null
    //text color als in midden top
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  allReducers,
  initialState,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;

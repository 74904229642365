import React, { useState } from "react";
import "./SingleHouse.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import '../../../App.css';
import MapComponent from "./mapComponent";


function SingleHouse(props) {

    // alert( document.getElementById('html').style.zoom)
    let history = useHistory();
    const [isDescriptionOpened, SetIsDescriptionOpened] = useState(null);

    if (isDescriptionOpened === null) {    //first time only (not on rerender)
        window.scrollTo(0, 0)
        SetIsDescriptionOpened(false)
        return;
    }

    const houseId = parseInt(window.location.pathname.split('/')[2]);
    const House = Filter2OneHouse();                            //Could use state because otherwise the Filter2OneHouse will get called multiple times 

    function getNumberWithCommas(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return null
    }

    const prijsSoort = (sale_condition) => {
        switch (sale_condition) {
            case 1: return "k.k"
            case 2: return "v.o.n"
            case 3: return "p/m"
            case 4: return "per kwartaal"
            case 5: return "per half jaar"
            case 6: return "per jaar"
            case 7: return "per contract"
            case 8: return "per m²"
            case 9: return "per m²"

            default: return null;
        }
    }

    function Filter2OneHouse() {
        for (let i = 0; i < props.AllHouses.length; i++) {
            if (props.AllHouses[i].id === houseId) { //check houseid

                return props.AllHouses[i]
            }
        }
        return false;
    }

    if (!House) {
        const firstPartUrl = window.location.pathname.split('/')[1].toString()
        history.push("/" + firstPartUrl);
        return null;
    }


    function translate(value1 = '', value2 = '', value3 = '') {       //Also filters if empty
        if (value1 && value1 === value1.toUpperCase) {
            value1 = value1.toLocaleLowerCase;
        }
        if (value1 !== null && typeof value1 !== 'undefined' && value1 !== 0 && value1 !== '0' && value2 !== null) { //if the building is from the actual year 0 it will get filtered, so that is not possible here
            return value1 + " " + value2 + " " + value3;
        }
    }

    const topIconData = [
        {
            title: "Bouwjaar",
            content: translate(House.year_built),
            icon: require('../../../assets/pictures/calendar-alt.svg')
        },
        {
            title: "Aantal kamers",
            content: translate(House.number_of_rooms),
            icon: require('../../../assets/pictures/door.svg')
        },
        {
            title: "Perceel",
            content: translate(House.plot_area, "m²"),
            icon: require('../../../assets/pictures/ruler-combined.svg')
        },
        {
            title: "Bruikbare ruimte",
            content: translate(House.usable_area, "m²"),
            icon: require('../../../assets/pictures/home.svg')

        },
    ]

    const tableData = [
        {
            title: "Overdracht",
            content: {
                'Status': translateStatus(House.sale_type, House.sale_status),
                'Vraagprijs': translate("€", getNumberWithCommas(House.price), ",-" + prijsSoort(House.sale_condition))
            }
        },
        {
            title: "Gebouw",
            content: {
                'Soort gebouw': ["Woning", "Appartement"][House.type - 1],
                'type': translate(House.og),
                'Bouwjaar': translate(House.year_built),
            }
        }, {
            title: "Indeling",
            content: {
                'Aantal kamers': House.number_of_rooms,
                'Aantal slaapkamers': House.bedrooms,
            }
        },
        {
            title: "Oppervlakte",
            content: {
                'Leefruimte': translate(getNumberWithCommas(House.usable_area), "m²"),
                'Perceel': translate(getNumberWithCommas(House.plot_area), "m²"),
                'Volume area': translate(getNumberWithCommas(House.volume_area), "m²"),
            }
        }, {
            title: "Overig",
            content: {
                'Energie label': House.energy_label,
                'Gemeubileerd': ["Nee", "Ja"][House.is_furnished],
                'Gestoffeerd': ["Nee", "Ja"][House.is_upholstered],
            }
        }
    ]

    function mappedBlockContent(block) {
        return (
            Object.keys(block).map((content, index) => ( //dit is per 'blok/tote;'
                Object.values(block)[index] && Object.values(block)[index] !== "null" ?
                    <tr key={index}>
                        <td>{Object.keys(block)[index]} </td>
                        <td>{Object.values(block)[index]}</td>
                    </tr>
                    : null
            ))
        )
    }

    const tableList = tableData.map((block, index) => ( //dit is per 'blok/tote;'       --
        <table className="table" key={"table" + index}>
            <tbody>
                <tr><th key={index}>{tableData[index].title}</th></tr>
                {mappedBlockContent(tableData[index].content)}
            </tbody>
        </table>
    ))

    function status() {
        //saleType is altijd 1 of 2 afhankelijk van of het iets met kopen of huren is
        //sale_status kan 1 - 6 zijn

        const translated = translateStatus(House.sale_type, House.sale_status, true);

        if (translated === false) {
            return;
        }
        return translated;
    }
    function translateStatus(sale_type, sale_status, label = false) {
        switch (sale_type + "." + sale_status) {
            //[Status, backgroundColor, textColor] @#@LabelColor + text color
            case '1.1':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>New</div>
                }
                return "New";
            case '1.2':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Te koop</div>
                }
                return "Te koop";
            case '2.2':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Te huur</div>
                }
                return "Te huur";
            case '1.3':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Verkocht o.v</div>
                }
                return "Verkocht o.v";
            case '1.4':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Verkocht</div>
                }
                return "Verkocht";
            case '2.5':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Verhuurd</div>
                }
                return "Verhuurd";
            case '2.6':
                if (label) {
                    return <div className="status" style={{ backgroundColor: props.organisation.primary_color, color: '#fff' }}>Verhuurd o.v</div>
                }
                return "Verhuurd o.v";

            default:
                return false;
        }
    }




    return (
        //     doesAgencyExist ?
        <div className="singleHouse" data-tooltip="red">
            <div className="top-container pt-4 pb-3" >
                <h1 className="font_semiBold">{House.address_1} {House.house_number}{House.house_number_addition},</h1><h5 className="font_italic"> {House.zipcode} {House.city}</h5>
                <h2 > €{getNumberWithCommas(House.price) + ",- " + prijsSoort(House.sale_condition)}</h2>
            </div>

            <div className="picture-container">
                {status()}
                <div className="extraRow">

                    <div className="iconContainer">
                        <Link style={{ display: 'contents' }} to={window.location.pathname + "/images"}>
                            <span style={{ backgroundColor: props.organisation.secondary_color, 'WebkitMask': "url(" + require('../../../assets/pictures/images.svg') + ") no-repeat center" }} alt="" className="imagesLogo"></span>
                            <span style={{
                                color: props.organisation.secondary_color
                            }}>Alle media</span>
                        </Link>
                    </div>
                </div>

                <Link style={{ display: 'contents' }} to={window.location.pathname + "/images/" + House.media[0].id}>
                    <img className="main-picture" src={House.media[0].url} alt="" />
                </Link>

                <div className="side-pictures">
                    {House.media[1] ?
                        <Link to={window.location.pathname + "/images/" + House.media[1].id}>
                            <img className="s-img" src={House.media[1].url} alt="" />

                        </Link> : <div className="s-img"></div>}
                    {House.media[2] ?
                        <Link to={window.location.pathname + "/images/" + House.media[2].id}>
                            <img className="s-img mr-0" src={House.media[2].url} alt="" />

                        </Link> : <div className="s-img"></div>}

                </div>
            </div>
            <div className="iconRow">
                {
                    topIconData.map((item, index) =>
                        item.content && item.content.length > 0 ?
                            <div className="iconContainer" key={"iconRow" + index}>
                                <div className="icon-row-icon mb-2" style={{ backgroundColor: props.organisation.primary_color, 'WebkitMask': "url(" + item.icon + ") no-repeat center" }} alt=""></div>
                                <div className="font_semiBold mb-1">{item.title}</div>
                                <div>{item.content}</div>
                            </div> : null
                    )
                }
            </div>


            {House.description ?
                <div className="descriptionContainer">
                    <h4 className="font_semiBold">Omschrijving</h4>
                    <div className={isDescriptionOpened ? "description" : "closedDescription description"}>{House.description}</div>
                    <b className="svg-icon button" style={{ backgroundColor: props.organisation.secondary_color }} onClick={() => SetIsDescriptionOpened(!isDescriptionOpened)}>{isDescriptionOpened ? "- Sluit " : "+ Open "}Beschrijving</b>

                </div>
                : null}



            <div className="table">
                <h4 className="font_semiBold">Kenmerken</h4>
                {tableList}
            </div>
            <div className={props.appStatus ? "mapContainer noLinks" : "mapContainer"}>
                <MapComponent house={House} appStatus={props.appStatus}></MapComponent>
            </div>
        </div>
    );
}



function mapStateToProps(state) {
    return {
        AllHouses: state.AllHouses,
        organisation: state.organisation,
        appStatus: state.appStatus,
    }
}

export default connect(mapStateToProps)(SingleHouse);



import React from 'react';
import "./GolfDisplayComponent.css";

const GolfDisplayComponent = ({ golfData, version }) => {
    return (
        <div className="flex flex-top">
            <div className="card-logo">
                <div className="card-logo--logo"></div>
            </div>
            {golfData.length > 0 ? (
                <div className="header-text header-text-end fade-in-text">
                    <span>Geniet van het golfen,</span>
                    <span>speel van de juiste tee!</span>
                </div>
            ) : (
                <div className="header-text">
                    {version === 'version-one' ? (
                        <span className="header-text--first-span">Welkom bij de<br/> Hooge Graven golfclub</span>
                    ) : (
                        <span className="header-text--first-span">Welkom bij de<br/> Rosendaelsche golfclub</span>
                    )}
                    <span className="header-text--second-span">Bereken uw baanhandicap</span>
                </div>
            )}
        </div>
    );
};

export default GolfDisplayComponent;

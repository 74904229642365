import React from "react";
import "./HeaderSlide.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectState } from '../../store/actions/index'


function ViewSwitch(props) {

    function changeView(newView) {
        if (newView === props.viewState) {
            return;
        }
        props.selectState(newView);
    }

    return (
        <div id="viewSwitch">
            <div className={props.viewState === "Display1" ? 'selected-icon1 icon' : 'icon'} style={{ backgroundColor: props.viewState === "Display1" ? props.secondary_color : '' }} onClick={() => changeView("Display1")}>
                <div className="svg-icon" style={{ backgroundColor: '#ffffff', 'WebkitMask': "url(" + require("../../assets/pictures/list.svg") + ") no-repeat center" }} ></div>
            </div>

            <div className={props.viewState === "Display2" ? 'selected-icon2 icon' : 'icon'} style={{ backgroundColor: props.viewState === "Display2" ? props.secondary_color : '' }} onClick={() => changeView("Display2")}>
                <div className="svg-icon" style={{ backgroundColor: '#ffffff', 'WebkitMask': "url(" + require("../../assets/pictures/th-large.svg") + ") no-repeat center" }}  ></div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        viewState: state.activeViewState
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ selectState: selectState }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ViewSwitch);

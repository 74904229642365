import React, { useState } from "react";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
//Leaflet-geonames = smaller but couldnt get it to work
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import "./SingleHouse.css";



function MapComponent(props) {
    // console.log('component word opnieuw ingeladen')
    const searchTerm = (props.house.city + " " + props.house.address_1 + " " + props.house.house_number + "" + props.house.house_number_addition).replace('null', "").replace('undefined', "");
    const [longLatLoaded, setLongLatLoaded] = useState(null);
    const [mapType, setMapType] = useState('https://{s}.tile.osm.org/{z}/{x}/{y}.png');
    // setup
    const provider = new OpenStreetMapProvider();

    // search

    if (longLatLoaded === null) {
        address2latlon()
    }

    async function address2latlon() {
        const results = await provider.search({ query: searchTerm });
        //@#@ countrycodes: 'ca,us', voor effectievere code - als de app het opeens niet meer doet mag het eruit hoor

        if (results.length > 0) {
            setLongLatLoaded([results[0].y, results[0].x])
        }
        else {
            setLongLatLoaded(false)
        }
    }


    if (longLatLoaded === null) {
        return <div style={{ width: "100%", backgroundColor: '#D3D3D3' }}></div>
    }

    if (longLatLoaded && longLatLoaded.length === 2) {
        var mapState = {
            lat: longLatLoaded[0],
            lng: longLatLoaded[1],
            zoom: 15
        }
        const position = [mapState.lat, mapState.lng];
        return (
            <>
                <div className="mapTypeButton">
                    {
                        mapType === 'https://{s}.tile.osm.org/{z}/{x}/{y}.png' ?
                            <div onClick={() => setMapType('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}')}>Satelliet</div> :
                            <div onClick={() => setMapType('https://{s}.tile.osm.org/{z}/{x}/{y}.png')}>Kaart</div>
                    }
                </div>
                <Map maxBoundsViscosity='1.0' maxBounds={[
                    //south west
                    [34, -34],
                    //north east
                    [71, 44] ]}

                    center={position} zoom={mapState.zoom} minZoom='4' scrollWheelZoom={false}>
                    {props.appStatus ? //If no appstatus = in browser you can LINK, if appStatus true 
                        <TileLayer attribution='&copy; <a href="https://OpenStreetMap.org/">www.OpenStreetMap.org</a> | &copy; <a href="https://opendatacommons.org/">www.openDataCommons.org</a>&emsp;' url={mapType} />
                        :
                        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors &emsp;' url={mapType}/>
                    }

                    {/*icon={customMarkerIcon} add to  */}
                    <Marker position={position} >
                        <Popup>
                            <span> {props.house.address_1} {props.house.house_number}{props.house.house_number_addition} <br />{props.house.city}</span>
                        </Popup>
                    </Marker>

                </Map>
            </>
        );
    }

    if (longLatLoaded === false || true) {
        return <b>Geen map locatie gevonden met huidige adresgegevens :(</b>
    }
}


export default MapComponent;
import React from "react";
import ContentSlide from "../components/contentSlide/ContentSlide.js"
import HeaderSlide from "../components/headerSlide/HeaderSlide.js";
import { Link } from "react-router-dom"
import { connect } from "react-redux";


function HousePage(props) {
    return (
        <div id="housePage" style={{ display: 'grid' }}>
            <Link to={window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}>
                <HeaderSlide></HeaderSlide>
            </Link>
            <ContentSlide item="singleHouse"></ContentSlide>
        </div>
    )
}
function mapStateToProps(state) {
    return { organisation: state.organisation }
}

export default connect(mapStateToProps)(HousePage)




//https://branchetv-acceptance.sqitsup.nl/player/b5837612-3e56-11e9-b6f4-2a176576c4be/slides
//https://app.viewiemedia.nl/player/92e716be-79fc-11e8-a216-a6ee344e7d35/slides
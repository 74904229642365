import React from 'react';
import Drawer from '@material-ui/core/Drawer';

import "../headerSlide/HeaderSlide.css";
import FilterForm from './FilterForm/FilterForm';

export default function PersistentDrawerLeft(props) {


  //  .app, root of mainpage moet height 100vh +  logoBottom moet weg

 
    const [open, setOpen] = React.useState(false);

    const [blockDisplay, setBlockDisplay] = React.useState(false);

    const handleDrawerOpen = () => {
        setBlockDisplay(true)
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setBlockDisplay(false)
        setOpen(false);
    };
  
    return (
        <div id="Drawer">
            {blockDisplay && <div className="bigBlackBlock" onClick={handleDrawerClose}></div>}
            <div className="icon" onClick={handleDrawerOpen}>
                <div style={{  'WebkitMask': "url(" + require("../../assets/pictures/sliders-h.svg") + ") no-repeat center" }} alt="" className="svg-icon" ></div>
            </div>

            <Drawer
         
                className="Drawer"
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: "Drawer",
                }}>
                <FilterForm onChildClick={() => handleDrawerClose()} ></FilterForm>
            </Drawer>
        </div>
    );
}
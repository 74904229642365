export default function (state = [], action) {

    switch (action.type) {
      case "SEARCHBAR_UPDATED":
  
        return action.payload;
      default:
        return state;
    }
  }
  
export const selectState = state => {
  return {
    type: "VIEWSTATE_SELECTED",
    payload: state
  };
};

export const updateAppStatus = state => {

  return {
    type: "APPSTATUS_UPDATED",
    payload: state
  };
};

export const updateOrganisation = state => {
  return {
    type: "ORGANISATION_UPDATED",
    payload: state
  };
};
export const updateAllHouses = state => {
  return {
    type: "ALL_HOUSES_UPDATED", //ALL HOUSES THAT GET CALLED IN APP.js TAKE THIS ACTION
    payload: state //reducer-data should at some point get deleted
  };
};

export const updateDisplayedHouseList = state => dispatch => {
  dispatch({
    type: "DISPLAYED_HOUSELIST_UPDATED",
    payload: state
  });
};
export const updateSearchBar = state => dispatch =>{
  dispatch({
    type: "SEARCHBAR_UPDATED",
    payload: state
  });
};
export const updateRequirements = state => dispatch =>{
  dispatch({
    type: "ALL_REQUIREMENTS_UPDATED",
    payload: state
  });
};

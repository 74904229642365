import React, { useState, useRef } from "react";
import "./ImageList.css";
import { Link } from "react-router-dom";
import '../../App.css';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";



function ImageList(props) {
    let history = useHistory();
    const houseId = parseInt(window.location.pathname.split('/')[2]);
    const EarlierUrl = window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/images"));    //= username/houseId  
    const lastPartUrl = parseInt(window.location.pathname.split("/").pop())           //imageId
    var IsImageSelected = false;

    const ref = useRef(null)
    var newimage = new Image();
    newimage.src = null;

    var numberOfImage = null;
    //The Value of image, will be null when the page loads unless handleOpenedImageAtMount() changes that

    const [loadedImages, setloadedImages] = useState(null)
    const [extraImageWidth, setExtraImageWidth] = useState(false)

    if (!loadedImages) {
        for (let i = 0; i < props.AllHouses.length; i++) {
            if (props.AllHouses[i].id === houseId) { //check houseid
                var foundHouse = props.AllHouses[i].media;
                setloadedImages(foundHouse);
                return;
            }
        }
    }


    if (!loadedImages) {
        const firstPartUrl = window.location.pathname.split('/')[1].toString()
        history.push("/" + firstPartUrl);
        return null;
    }
    function UrlNr2Index(UrlNr) {
        for (let i = 0; i < loadedImages.length; i++) {
            if (UrlNr === loadedImages[i].id) {
                return i;
            }
        }
    }


    handleOpenedImageAtMount()
    function handleOpenedImageAtMount() {
        try {

            if (!isNaN(lastPartUrl) && loadedImages[UrlNr2Index(lastPartUrl)].url !== newimage.src) {


                newimage.src = loadedImages[UrlNr2Index(lastPartUrl)].url
                IsImageSelected = true;



                newimage.onload = function () {                           //If image is too small for screen(only o)

                    if (newimage.naturalHeight < ref.current.offsetHeight
                        && newimage.naturalWidth < ref.current.offsetWidth

                        //&&width van afbeelding = kleiner dan schermbreedte
                    ) {

                        //als width niet zo breed is als mogelijke width && je kan verbreden zonder voorbei height te gaan


                        //uitvinden hoeveel je kan verbreden zonder alles te slopen
                        var ratioWidthvsH = newimage.naturalWidth / newimage.naturalHeight;
                        var diffrenceInHeight = ref.current.offsetHeight - newimage.naturalHeight;
                        var diffrenceInWidth = diffrenceInHeight * ratioWidthvsH;
                        //als je hem 100% height zou geven hoe breedt zou het dan worden  (als je diffrenceinheight erbij telt hoeveel komt er dan bij voor breedte)
                        //geef breedte
                        if (extraImageWidth !== newimage.naturalWidth + diffrenceInWidth) {

                            setExtraImageWidth(newimage.naturalWidth + diffrenceInWidth);
                        }

                    }

                }

                for (let i = 0; i < loadedImages.length; i++) {
                    if (loadedImages[i].url === newimage.src) {
                        numberOfImage = i + 1 + "/" + loadedImages.length
                    }
                }

            }
        } catch{
            //example: The lastPartUrl doesnt have an image or pivot.url
        }
        return;
    }

    if (newimage.src !== null) {
        for (let i = 0; i < loadedImages.length; i++) {
            if (loadedImages[i].url === newimage.src.url) {
                return i + " / " + loadedImages.length
            }
        }
    }

    function slide(direction) {       //Pushes history(route) according to direction and index
        const index = UrlNr2Index(lastPartUrl);
        if (direction === "left") {
            if (index > 0) {
                history.push(EarlierUrl + "/images/" + loadedImages[index - 1].id);
            }
            else {                            //Your index is to Low -> go back to last image
                history.push(EarlierUrl + "/images/" + loadedImages[loadedImages.length - 1].id);
            }
        }
        else if (direction === "right") {
            if (index + 1 < loadedImages.length) {
                history.push(EarlierUrl + "/images/" + loadedImages[index + 1].id);
            }
            else {                              //Your index is to high -> go back to first image
                history.push(EarlierUrl + "/images/" + loadedImages[0].id);
            }
        }
        return null;
    }

    document.onkeydown = GetDirectionFromKey;
    function GetDirectionFromKey(e) {              //When the left/right arrow(on the keyboard) het translates that to the direction in which the slide() function will be called
        if ((newimage.src && (e.keyCode === 37 || e.keyCode === 39) && Array.isArray(loadedImages))) {
            if (e.keyCode === 37
            ) { //Links
                slide("left")
            }
            if (e.keyCode === 39) {
                slide("right")
            }
        }
    }

    return (

        <div className={"ImageList"}   >
            <Link to={EarlierUrl}>
                <div className="returnContainer">
                    <img className="return svg-icon" style={{ backgroundColor: props.organisation.secondary_color, 'WebkitMask': "url(" + require("../../assets/pictures/times.svg") + ") no-repeat" }} alt="" />
                </div>
            </Link>



            <h2 className="title font_semiBold" style={{ color: props.organisation.primary_color }} >Afbeeldingen</h2>
            <div className={!IsImageSelected ? "ImageGrid" : "ImageGrid small"}>
                {
                    loadedImages.map((house, index) =>

                        <Link key={"Link" + index} to={EarlierUrl + "/images/" + loadedImages[index].id}>
                            {/* <div key={index} className="image" style={{ backgroundImage: "url(" + loadedImages[index].url + ")" }}>  </div> */}

                            <img className="image" src={loadedImages[index].url} alt="" />

                        </Link>
                    )
                }
            </div>

            {loadedImages === false ? "Geen afbeeldingen gevonden" : null}
            {
                IsImageSelected ?
                    <div className="selectContainer" >

                        <div className="arrowContainer left"><img onClick={() => slide("left")} className="svg-icon arrow" style={{ 'WebkitMask': "url(" + require("../../assets/pictures/chevron-left.svg") + ") no-repeat center" }} alt="" /></div>
                        <div className="arrowContainer right"><img onClick={() => slide("right")} className="svg-icon arrow" style={{ 'WebkitMask': "url(" + require("../../assets/pictures/chevron-right.svg") + ") no-repeat center" }} alt="" /></div>

                        <Link to={EarlierUrl}>
                            <div className="blackBlock"></div>
                        </Link>
                        <div className={"selectedPictureContainer"} ref={ref}>
                            <img src={newimage.src} alt="Geen geldige afbeelding gevonden" style={{ width: extraImageWidth ? extraImageWidth + 'px' : "" }} className="selectedPicture" />
                        </div>
                        <Link to={EarlierUrl + '/images'}>

                            <div className="exitImageButtonContainer" style={{ backgroundColor: props.organisation.secondary_color }}>

                                {/* <img className="exitImageButton svg-icon" style={{ backgroundColor: props.organisation.secondary_color, 'WebkitMask': "url(" + require("../../assets/pictures/images.svg") + ") no-repeat" }} alt="" /> */}


                                {numberOfImage} | <span>Alle Media</span>
                            </div>
                        </Link>


                    </div>
                    : null
            }
        </div>
    );
}

function mapStateToProps(state) {

    return {
        organisation: state.organisation,
        AllHouses: state.AllHouses,    //Has to go through all HouseData regardless to find id
    }
}

export default connect(mapStateToProps)(ImageList);




